  .FeaturePopup {
    width:200px;
    height:20px;
    background-color: red;
  }
.mapboxgl-popup-content {
    background-color:rgba(1,1,1,0);
    padding:0;
    box-shadow:none;
  }
.ZoomPopup {
  width: "auto";
  height: "auto";
  padding-left: 5px;
  padding-right:5px;
  background-color:rgba(255,255,255,0.8);
  border-radius:8px;
  user-select:none;
}
.mapboxgl-popup {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color:rgba(1,1,1,0);
  will-change: transform;
  pointer-events: none;
  user-select:none;

}

.ClickPopup{
  width: 150px;
  height: "auto";
  padding-left: 5px;
  padding-right:5px;
  background-color:rgba(255,255,255,0.8);
  border-radius:8px;
}

.carSize{
  width:30px;
  height:"auto";
}

.mapboxgl-popup-tip {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  z-index: 1;
  display:none;
}
.mapboxgl-canvas {
  left: 0;
}