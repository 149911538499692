@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.gallery-scroll {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.gallery-scroll::-webkit-scrollbar {
  width: 10px;
}

.gallery-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.gallery-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.gallery-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gallery-scroll {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
