s.webapp-grid-container {
    
        display: flex; 
        flex-direction:column;
        height: 100vh;
        width:100vw;
        overflow:hidden;
}

.webapp-grid-top-segment {
    height: 24%;
    width: 100%;
    overflow: hidden;
    border-bottom:2px solid #ddd;
}

.webapp-grid-content {
    flex: 1;
    display:flex;
    flex-direction:row;
    padding:0;
}

.webapp-grid-items-gallery {
    width: 30vw;
    overflow: hidden;
}

.webapp-grid-page {
    flex: 1;
    width:70vw;
    height:82vh;
}
