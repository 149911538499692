.top-segment-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom:0;
  }
  
  .left-section {
    width: 60%;
    height: 100%;
    align-items: baseline;
  }
  
  .image-container {
    height: 70%;
    overflow: hidden;
    text-align: start;
  }
  
  .image-container img {
    height: 100%;
  }
  
  .title-container {
    height: 30%;
    width: 100%;
  }
  
  .right-section {
    width: 55%;
    height: 100%;
  }
  
  .content-container {
    width: 100%;
    height: 60%;
  }
  
  .buttons-container {
    width: 100%;
    height: 40%;
    border: 2px solid black;
    padding: 2px;
    display: flex;
    flex-direction: row;
  }
  
  .buttons-container > div {
    width: 20%;
    height: 85%;
    background-color: green;
    border-radius: 10px;
  }