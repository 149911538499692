.startpage-container {
    width:100%;
    height:100%;
    margin-top:0;
    display:flex;
}   

.image-gallery {
    padding:10px;
    overflow-x:auto;
    margin-top:10px;
  }
  
  .gallery-image {
    width: 100%;
    margin-left:5px;
    margin-right:5px;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
  }
  